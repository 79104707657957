exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-allimages-jsx": () => import("./../../../src/pages/allimages.jsx" /* webpackChunkName: "component---src-pages-allimages-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-donate-jsx": () => import("./../../../src/pages/donate.jsx" /* webpackChunkName: "component---src-pages-donate-jsx" */),
  "component---src-pages-error-jsx": () => import("./../../../src/pages/error.jsx" /* webpackChunkName: "component---src-pages-error-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-karakung-index-jsx": () => import("./../../../src/pages/karakung/index.jsx" /* webpackChunkName: "component---src-pages-karakung-index-jsx" */),
  "component---src-pages-lab-reports-jsx": () => import("./../../../src/pages/lab_reports.jsx" /* webpackChunkName: "component---src-pages-lab-reports-jsx" */),
  "component---src-pages-labnotes-jsx": () => import("./../../../src/pages/labnotes.jsx" /* webpackChunkName: "component---src-pages-labnotes-jsx" */),
  "component---src-pages-morph-jsx": () => import("./../../../src/pages/morph.jsx" /* webpackChunkName: "component---src-pages-morph-jsx" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-r-d-jsx": () => import("./../../../src/pages/r&d.jsx" /* webpackChunkName: "component---src-pages-r-d-jsx" */),
  "component---src-pages-thankyou-jsx": () => import("./../../../src/pages/thankyou.jsx" /* webpackChunkName: "component---src-pages-thankyou-jsx" */),
  "component---src-pages-toolkit-cellcosts-jsx": () => import("./../../../src/pages/toolkit/cellcosts.jsx" /* webpackChunkName: "component---src-pages-toolkit-cellcosts-jsx" */),
  "component---src-pages-toolkit-index-jsx": () => import("./../../../src/pages/toolkit/index.jsx" /* webpackChunkName: "component---src-pages-toolkit-index-jsx" */),
  "component---src-pages-toolkit-loads-jsx": () => import("./../../../src/pages/toolkit/loads.jsx" /* webpackChunkName: "component---src-pages-toolkit-loads-jsx" */),
  "component---src-pages-tools-jsx": () => import("./../../../src/pages/tools.jsx" /* webpackChunkName: "component---src-pages-tools-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-image-jsx": () => import("./../../../src/templates/image.jsx" /* webpackChunkName: "component---src-templates-image-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

